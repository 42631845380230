import React from 'react';
import { Link } from 'react-router-dom';
import HomeThreeSingleProject from '../../../../components/HomeThreeSingleProject/HomeThreeSingleProject';
import { FaMapMarkerAlt } from 'react-icons/fa';

const HomeThreeProjects = () => {

   return (
      <>
         <section className="tp-project-area-three pb-90">
           

            <div className="container">
               <div className="row align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                     <div className="tp-project-title-wrapper wow fadeInUp" data-wow-delay=".2s">
                        <h2 className="tp-section-subtitle-three mb-20">Our Services _ _</h2>
                        <h5 className="tp-section-title-two color-theme-blue mb-45">Helping you plan, style, create the perfect party</h5>
                        <div className="tp-project-title-btn">
                           <Link to="/projectsDetails" className="yellow-btn"><i className="flaticon-enter"></i> More Services</Link>
                        </div>
                     </div>
                  </div>


                  <HomeThreeSingleProject column="4" columnTwo="6" image_num="5"
                  title="Birthday Party" subTitle="Balloon Decorations" />

                  <HomeThreeSingleProject column="4" columnTwo="6" image_num="6"
                  title="Corporate Event" subTitle="Event Decoration Service" />

                  <HomeThreeSingleProject column="4" columnTwo="6" image_num="7"
                  title="Event Hosting Service" subTitle="Event Hosting Service" />

                  <HomeThreeSingleProject column="8" columnTwo="12" image_num="8"
                  title="Table Decorations" subTitle="Table Decorations" />

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeProjects;