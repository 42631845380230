import React from 'react';
import HomeThreeSingleFeature from '../../../../components/HomeThreeSingleFeature/HomeThreeSingleFeature';

const HomeThreeFeatures = () => {
   return (
      <>
         <section className="tp-feature-area-three pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="section-title-wrapper text-center mb-55 wow fadeInUp" data-wow-delay=".2s">
                        <h5 className="tp-section-subtitle-three mb-20"> _ _ About Us _ _</h5>
                        <p className="tp-feature-three-text p">Festa Rentals is a event decorator and party rental company located in the East Bay. <br/>By mixing appropriate lighting, furnishings, linens, cutlery, and floral design, we create an elegant party environment to make your dream event come to life.<br/> In addition, if you would like to organize the event planning and decorations yourselves, we also rent out all kinds of party related items.</p>
                        
                     </div>
                  </div>
               </div>
               <div className="row">
                 
                  <HomeThreeSingleFeature icon="booking" title="10+ Years Of Experience" />
                  <HomeThreeSingleFeature icon="delivery-box" title="1200+ Events Executed" />
                  <HomeThreeSingleFeature icon="boy" title="50+ Team Members" />
                  <HomeThreeSingleFeature icon="cleaning" title="1000+ Clients Served" />
           
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeFeatures;